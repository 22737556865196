var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-end mb-50"},[_c('b-btn',{staticClass:"mr-50",attrs:{"variant":"success","size":"sm","disabled":_vm.loading},on:{"click":_vm.onSubmit}},[_vm._v("บันทึก")]),_c('b-btn',{attrs:{"variant":"primary","size":"sm","disabled":_vm.loading},on:{"click":_vm.onClickPreviewContent}},[_vm._v("ดูตัวอย่าง")])],1),_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-row',{attrs:{"cols":"1"}},[_c('b-col',[_c('b-form-group',{attrs:{"label":"หัวข้อประกาศ","label-for":"announce-title"}},[_c('b-form-input',{model:{value:(_vm.form.announce_title),callback:function ($$v) {_vm.$set(_vm.form, "announce_title", $$v)},expression:"form.announce_title"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"รายละเอียดโดยย่อ","label-for":"announce-sub-details"}},[_c('b-form-textarea',{attrs:{"rows":"3"},model:{value:(_vm.form.announce_sub_details),callback:function ($$v) {_vm.$set(_vm.form, "announce_sub_details", $$v)},expression:"form.announce_sub_details"}})],1)],1),_c('b-col',[_c('label',{attrs:{"for":"announce-html-contents"}},[_vm._v("เนื้อหาประกาศ")]),_c('Editor',{attrs:{"api-key":"bx2fvbl9jedhu7n7p8an5tnfmxxqfnig057d38gms9hbrxpu","init":{
            plugins:
              'link image fullscreen lists emoticons code autoresize directionality visualblocks visualchars table',
            toolbar:
              'undo redo | bold italic underline strikethrough | forecolor backcolor emoticons | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | image | fullscreen | code',
            menubar: 'format table',
            content_style: 'img { max-width: 100%; height: auto; }',
            image_class_list: [
              { title: 'default', value: '' },
              { title: 'responsive', value: 'custom-tiny-mce-image-preview-responsive' } ],
            min_hight: '300px',
          }},model:{value:(_vm.form.announce_html_contents),callback:function ($$v) {_vm.$set(_vm.form, "announce_html_contents", $$v)},expression:"form.announce_html_contents"}})],1)],1)],1),_c('b-modal',{ref:"refModalPreviewContents",attrs:{"centered":"","size":"xl","title":"ตัวอย่าง","hide-footer":""}},[_c('h3',[_vm._v(_vm._s(_vm.form.announce_title))]),_c('hr'),_c('div',{domProps:{"innerHTML":_vm._s(_vm.form.announce_html_contents)}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
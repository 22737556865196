<template>
  <div>
    <div class="d-flex justify-content-end mb-50">
      <b-btn variant="success" size="sm" :disabled="loading" @click="onSubmit" class="mr-50">บันทึก</b-btn>
      <b-btn variant="primary" size="sm" :disabled="loading" @click="onClickPreviewContent">ดูตัวอย่าง</b-btn>
    </div>
    <b-overlay :show="loading">
      <b-row cols="1">
        <b-col>
          <b-form-group label="หัวข้อประกาศ" label-for="announce-title">
            <b-form-input v-model="form.announce_title" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="รายละเอียดโดยย่อ" label-for="announce-sub-details">
            <b-form-textarea v-model="form.announce_sub_details" rows="3" />
          </b-form-group>
        </b-col>
        <b-col>
          <label for="announce-html-contents">เนื้อหาประกาศ</label>
          <Editor
            v-model="form.announce_html_contents"
            api-key="bx2fvbl9jedhu7n7p8an5tnfmxxqfnig057d38gms9hbrxpu"
            :init="{
              plugins:
                'link image fullscreen lists emoticons code autoresize directionality visualblocks visualchars table',
              toolbar:
                'undo redo | bold italic underline strikethrough | forecolor backcolor emoticons | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | image | fullscreen | code',
              menubar: 'format table',
              content_style: 'img { max-width: 100%; height: auto; }',
              image_class_list: [
                { title: 'default', value: '' },
                { title: 'responsive', value: 'custom-tiny-mce-image-preview-responsive' },
              ],
              min_hight: '300px',
            }"
          />
        </b-col>
      </b-row>
    </b-overlay>

    <b-modal ref="refModalPreviewContents" centered size="xl" title="ตัวอย่าง" hide-footer>
      <h3>{{ form.announce_title }}</h3>
      <hr />
      <div v-html="form.announce_html_contents"></div>
    </b-modal>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'

export default {
  components: {
    Editor,
  },
  data() {
    return {
      form: {
        id: '',
        announce_title: '',
        announce_sub_details: '',
        announce_html_contents: null,
      },
      loading: false,
    }
  },
  mounted() {
    const id = this.$route.params?.id
    if (id && id !== 'new') {
      this.fetchAnnounceById(id)
    }
  },
  methods: {
    async fetchAnnounceById(id) {
      this.loading = true

      const resp = await this.api.getV2(`api/announcement-data/${id}`).catch(() => null)
      if (resp && resp?.code === 200) {
        this.form = { ...resp.data }
      } else {
        this.gDisplayToast('พบข้อผิดพลาด', 'ไม่พบข้อมูลเดิมในระบบ', 'danger')
        this.$router.push({ name: 'announce', replace: true })
      }

      this.loading = false
    },
    async onSubmit() {
      const { announce_title, announce_sub_details, announce_html_contents, id } = this.form
      // eslint-disable-next-line camelcase
      if (!announce_title || !announce_sub_details || !announce_html_contents) {
        this.gDisplayToast('โปรดระบุข้อมูลให้ครบถ้วน', '', 'danger')
        return
      }

      const isConfirm = await this.gCheckConfirmV1(id ? 'แก้ไขประกาศ' : 'เพิ่มประกาศ')
      if (!isConfirm) return

      let resp = null

      this.loading = true

      if (id) {
        resp = await this.api.putV2(`api/admin/announcement-data/${this.form.id}`, this.form, this).catch(() => null)
      } else {
        resp = await this.api.postV2('api/admin/announcement-data', this.form, this).catch(() => null)
      }

      this.loading = false

      if (resp && resp?.code === 200) {
        if (id) {
          this.gDisplayToast('แก้ไขประกาศสำเร็จแล้ว')
        } else {
          this.gDisplayToast('เพิ่มประกาศสำเร็จแล้ว')
        }
        this.$router.push({ name: 'announce', replace: true })
      } else {
        this.gDisplayToast('พบข้อผิดพลาด', resp?.data?.message, 'danger')
      }
    },
    onClickPreviewContent() {
      // eslint-disable-next-line no-unused-expressions
      this.$refs.refModalPreviewContents?.show()
    },
  },
}
</script>

<style lang="scss" scoped></style>
